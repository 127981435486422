import { AlertType } from 'pages/ViewerPage/BroadcastMessage/getAlertType'
import { Game, Subscription, TimelineObject } from '.'
import { Price } from './Price.interface'
import { Subtitle } from './Subtitle.interface'

export enum AccessRestrictionType {
  LoginRequired = 'login_required',
  None = 'none',
  PaymentRequired = 'paywall',
  SubscriptionRequired = 'subscription_required',
  PaymentAndSubscriptionRequired = 'paywall_and_subscription'
}

export interface Category {
  slug: string
  translated_name?: string
  name?: string
}
/* eslint-disable camelcase */
export type MediaObject = {
  id: string
  ident: string
  duration: number
  thumbnail: string
  thumbnail_url: string
  type: 'livestream' | 'video'
  is_replay: boolean
  title: string
  description: string
  view_count: number
  active_view_count: number
  is_live: boolean
  is_live_and_started: boolean
  stats_handler_ident: string
  chat_messages_count: number
  enable_comments: boolean
  comments_count: number
  user_like_counts: number
  event_source_uri: string
  event_schedule: {
    id: number
    name: string
  }
  producer?: string
  slug: string
  link: string
  allow_download: boolean
  published_at: number
  updated_at: number
  created_at: number
  live_start_at: number
  live_end_at: number
  price: Price
  user_has_access: boolean
  user_is_admin: boolean
  game: Game
  stream_url: {
    hls: string
    dash: string
  }
  has_subtitles: boolean
  subtitles: Subtitle[]
  company: {
    subdomain: string
    name: string
  }
  categories: Category[]
  tags: {
    name: string
    slug: string
  }[]
  access_restriction: AccessRestrictionType
  payment_methods: {
    subscriptions: Subscription[]
    ppv: {
      price: Price
    }
  }
  highlight_source: {
    type: string
    slug: string
    company: {
      name: string
      subdomain: string
    }
  }
  highlight_slug: string
  related: TimelineObject[]
  broadcast_message: {
    color: AlertType
    message: string
  }
  ad_tag_url: string
  competition?: {
    type: string
    name: string
  }
  is_geoblock_protected?: boolean
}
