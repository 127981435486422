import React, { FC } from 'react'
import { StreamLabel } from '../../../../components/cards/BroadcastThumbnail/ThumbnailLabel/ThumbnailLabel'
import { ViewType } from '../../../../redux-store/stream/stream.interface'
import { EventSummary } from '../ActionPanel.interface'
import styles from './ActionLabel.module.scss'

interface ActionLabelBadgeProps {
  event: EventSummary
  viewType: ViewType
  isReport: boolean
}

export const ActionLabelBadge: FC<ActionLabelBadgeProps> = ({
  event,
  isReport,
  viewType
}) => {
  const isHighlight = viewType === ViewType.HIGHLIGHT
  const highlightFromDate = isHighlight && !isReport ? event.liveStartsAt : null
  switch (viewType) {
    case ViewType.HIGHLIGHT:
    case ViewType.LIVE:
    case ViewType.REPLAY:
      return (
        <div
          className={styles.ActionLabelBadge}
          data-testid="action-label-badge"
        >
          <StreamLabel
            isLive={viewType === ViewType.LIVE}
            isReplay={viewType === ViewType.REPLAY}
            isLiveReporting={isReport}
            startAt={event.liveStartsAt}
            type=""
            publishedAt={event.publishedAt}
            highlightFromDate={highlightFromDate}
          />
        </div>
      )
    default:
      return null
  }
}
