import React, { FC } from 'react'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import { t } from '@lingui/macro'

import { PlayIcon } from 'assets/icons/PlayIcon'
import { ShareButton } from './Buttons/ShareButton'
import { TicketsIcon } from 'assets/icons/TicketsIcon'

import { LikeStreamButton } from './Buttons/LikeStreamButton'
import ReplayIcon from 'assets/icons/ReplayIcon'
import useAdminAccess from 'hooks/AdminAccess'
import { userInterface } from 'redux-store/userInterface/userInterface.actions'
import { useDispatch } from 'react-redux'
import useCurrentUser from 'hooks/CurrentUser'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import styles from './CTASection.module.scss'
import { AccessRestrictionType } from 'interfaces'

interface Props {
  userHasAccess: boolean
  streamIsUpcoming: boolean
  streamHasDVR: boolean
  accessRestriction: AccessRestrictionType
}
export const CTASection: FC<Props> = ({
  userHasAccess,
  streamIsUpcoming,
  streamHasDVR,
  accessRestriction
}) => {
  const dispatch = useDispatch()
  const adminAccess = useAdminAccess()
  const currentUser = useCurrentUser()
  const hasChannelAccess = adminAccess.hasChannelAccess

  const getTextForPrimaryButton = () => {
    if (userHasAccess) {
      return t`Watch stream`
    } else {
      if (accessRestriction === AccessRestrictionType.LoginRequired) {
        return t`Watch stream`
      }
      return t`Buy stream`
    }
  }
  const primaryButton = getTextForPrimaryButton()
  const primaryIconToUse = userHasAccess ? <PlayIcon /> : <TicketsIcon />
  const handlePrimaryButtonClick = () => {
    if (userHasAccess) {
      dispatch(userInterface.openViewStream())
    } else {
      if (!currentUser) {
        dispatch(authenticationActions.openLoginLayer())
        return false
      }
      if (accessRestriction === AccessRestrictionType.LoginRequired) {
        dispatch(userInterface.openViewStream())
      } else {
        dispatch(userInterface.openPaymentDialog())
      }
    }
  }
  const handlePlayFromBegining = () => {
    dispatch(userInterface.openViewStream())
    //handling the play from begingin state when dvr and live
  }

  const showPrimaryButton = userHasAccess
    ? !streamIsUpcoming || hasChannelAccess
    : true

  const showPlayFromBeginingButton = userHasAccess && streamHasDVR

  return (
    <div className={styles.Container}>
      {showPrimaryButton && (
        <IconTextButton
          id={'primary-button'}
          type={ButtonType.CTA}
          buttonSize={'Medium'}
          label={primaryButton}
          onClick={() => handlePrimaryButtonClick()}
          isRounded
          data-testid="primary-button"
          externalStyle={styles.PrimaryButton}
        >
          {primaryIconToUse}
        </IconTextButton>
      )}
      {showPlayFromBeginingButton && (
        <IconTextButton
          id={'play-from-begining-button'}
          type={ButtonType.Ghost}
          buttonSize={'Medium'}
          label={t`Watch from begining`}
          onClick={handlePlayFromBegining}
          isRounded
          data-testid="watch-from-begining-button"
          externalStyle={styles.WatchFromBeginingButton}
        >
          <ReplayIcon />
        </IconTextButton>
      )}
      <div className={styles.InteractionButtonContainer}>
        {userHasAccess && <LikeStreamButton />}
        <ShareButton />
      </div>
    </div>
  )
}
