import React, { FC } from 'react'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import { BackArrowIcon } from 'assets/icons/BackArrowIcon'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import { StreamOptionsButton } from './StreamOptionsButton/StreamOptionsButton'

import styles from './TopActionsBar.module.scss'
import { ViewAsAdminButton } from './ViewAsAdminButton/ViewAsAdminButton'

export const TopActionsBar: FC = () => {
  const channel = useSelector(channelSelector)
  const channelSlug = channel.data.subdomain
  const history = useHistory()
  const handleOnBackClick = () => {
    history.push(`/${channelSlug}`)
  }

  return (
    <div className={styles.TopActionsBar}>
      <IconButton id={'back-arrow-button'} onClick={handleOnBackClick}>
        <BackArrowIcon />
      </IconButton>
      <div className={styles.RightContainer}>
        <ViewAsAdminButton />
        <StreamOptionsButton />
      </div>
    </div>
  )
}
