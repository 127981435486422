import React, { FC } from 'react'
import { t } from '@lingui/macro'

import {
  Breakpoint,
  useCurrentBreakpoint
} from '../../../../../hooks/CurrentBreakpoint'
import VideoCameraIcon from '../../../../../assets/icons/VideoCameraIcon'

import styles from './producedBy.module.scss'

interface Props {
  producer: string
}

export const ProducedBy: FC<Props> = ({ producer }) => {
  const currentBreakpoint = useCurrentBreakpoint()

  const broadcastedByText =
    currentBreakpoint < Breakpoint.mobile
      ? t`Produced by`
      : t`The broadcast is produced by`

  return (
    <div className={styles.ProducedByContainer}>
      <div className={styles.VideoCameraIcon}>
        <VideoCameraIcon />
      </div>
      <div className={styles.Text}>{broadcastedByText}</div>

      <div className={styles.Producer}>{producer}</div>
    </div>
  )
}
