import React, { FC, useEffect, useState } from 'react'
import styles from './StreamMessage.module.scss'
import { getAlertType } from './getAlertType'
import { useSelector } from 'react-redux'
import { MessageIcon } from './MessageIcon'
import { IconButton } from 'components/buttons/IconButton/IconButton'
import CloseIcon from 'components/page/header/components/hamburgerMenuContent/topContainer/CloseIcon'
import { streamSelector } from 'redux-store/stream/stream.selectors'

export const StreamMessage: FC = () => {
  const stream = useSelector(streamSelector)

  const broadcastMessage = stream?.data?.mediaObject?.broadcast_message
  const alertType = getAlertType(broadcastMessage?.color)

  const newMessage = useSelector(
    (state: any) => state.subscriber.newBroadcastMessage
  )

  const [alertTypeState, setAlertTypeState] = useState(alertType)
  const [messageText, setMessageText] = useState(broadcastMessage?.message)

  useEffect(() => {
    if (newMessage) {
      const alertType = getAlertType(newMessage.color)
      setAlertTypeState(alertType)
      setMessageText(newMessage.text)
    }
  }, [newMessage])

  if (messageText) {
    return (
      <div className={styles.StreamMessage}>
        <div className={styles.LeftContainer}>
          <div className={styles.Icon}>
            <MessageIcon alertType={alertTypeState} />
          </div>
          <div className={styles.TextContent}>{messageText}</div>
        </div>
        <IconButton id="close-icon" onClick={() => setMessageText('')}>
          <CloseIcon />
        </IconButton>
      </div>
    )
  }
  return null
}
