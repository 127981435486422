import React, { FC } from 'react'
import classNames from 'classnames'
import { t } from '@lingui/macro'

import menuItems, { ViewerPageMenuOption } from './StreamSubmenuLinks'
import { useSelector } from 'react-redux'
import { isSwedishHandballSelector } from 'redux-store/channel/channel.selectors'
import PageLink from 'components/page/menuLink/MenuLink'
import { isViewingStreamSelector } from 'redux-store/userInterface/userInterface.selectors'
import styles from './StreamSubmenu.module.scss'

interface ViewerPageSubmenuProps {
  onItemSelect: (id: number) => void
  selectedItemId: number
  broadcastPath: string
  pageHasChat: boolean
  canComment: boolean
  hasGame: boolean
}

export const StreamSubmenu: FC<ViewerPageSubmenuProps> = ({
  selectedItemId,
  onItemSelect,
  broadcastPath,
  pageHasChat,
  canComment,
  hasGame
}) => {
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const isViewingStream = useSelector(isViewingStreamSelector)
  const showGameLineups = isSwedishHandball

  const items = menuItems.filter((link) => {
    switch (link.id) {
      case ViewerPageMenuOption.Chat:
        return canComment
      case ViewerPageMenuOption.Statistics:
        return hasGame
      case ViewerPageMenuOption.Lineup:
        return hasGame && showGameLineups
      default:
        return true
    }
  })
  if (items.length === 1) {
    return isViewingStream ? <div className={styles.Divider} /> : null
  }
  return (
    <div
      className={classNames(styles.ViewerSubmenuContainer, {
        [styles.IsViewingStream]: isViewingStream
      })}
      data-testid="viewer-page-submenu-container"
    >
      <ul data-testid="viewer-page-submenu-list">
        {items.map((link) => {
          const isSelected = link.id === selectedItemId || items.length === 1
          return (
            <li
              data-testid={`viewer-page-submenu-${link.id}`}
              key={link.id}
              className={classNames({
                [styles.NotSelected]: !isSelected
              })}
              onClick={() => onItemSelect(link.id)}
              onKeyUp={(event) => {
                if (event.key === ' ') {
                  onItemSelect(link.id)
                }
              }}
            >
              {!pageHasChat && link.id === ViewerPageMenuOption.Chat ? (
                <PageLink
                  {...{
                    ...link,
                    link: `${broadcastPath}/comments`,
                    translationText: t`Comments`
                  }}
                />
              ) : (
                <PageLink
                  {...{ ...link, link: `${broadcastPath}${link.link}` }}
                />
              )}
              <br />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
