/* eslint-disable react/no-children-prop */
import React, { FC } from 'react'
import { Switch, Route } from 'react-router-dom'
import { PrivateRoute } from '../components/app/PrivateRoute/PrivateRoute'

import { UserSessionPage } from '../pages/UserSessionPage/UserSessionPage'
import BroadcastStartPage from '../pages/BroadcastStartpage/BroadcastStartPage'
import { ChannelPage } from '../pages/ChannelPage/index'

import { ChannelSettings } from '../pages/ChannelPage/ChannelSettings/ChannelSettings'
import LandingPage from '../pages/LandingPage'
import StartPage from '../pages/StartPage'
import { SearchResultPage } from '../pages/SearchResultPage/SearchResults'
import ViewerPage from '../pages/ViewerPage'
import { ReadNewsArticle } from '../pages/ChannelPage/News/ReadNewsArticle/ReadNewsArticle'
import NotFoundPage from '../pages/NotFoundPage'
import { Impressum } from '../pages/ChannelPage/Impressum/Impressum'
import { ContentManager } from '../pages/ChannelPage/ContentManager/ContentManager'

import { RefereeAccess } from 'features/refereeAccess/RefereeAccess'
import { SchedulePage } from 'pages/SchedulePage/SchedulePage'

import { UserAccountPage } from 'pages/UserAccountPage/UserAccountPage'
import { useFeatureFlags } from 'featureFlags/useFeatureFlags'
import { FeatureFlagsAvailable } from 'featureFlags/FeatureFlagsAvailable'
import { StreamPage } from 'features/viewerPage/StreamPage'

export const AppRoutes: FC<{
  channelDataIsReady: boolean
  channelDataExpected: boolean
  channelDataNotFound: boolean
}> = ({
  channelDataIsReady = false,
  channelDataExpected = false,
  channelDataNotFound = false
}) => {
  if (channelDataNotFound && channelDataExpected) {
    return <NotFoundPage />
  }
  const { getFlag } = useFeatureFlags()
  const showViewerPage2 = getFlag(FeatureFlagsAvailable.ShowViewerPage2)
  return (
    <Switch>
      <Route exact path="/">
        <StartPage />
      </Route>
      <Route path="/access_invite/:integration_id/:ident">
        <RefereeAccess />
      </Route>
      <Route path="/broadcast">
        <BroadcastStartPage />
      </Route>
      <Route path="/browse">
        <StartPage />
      </Route>
      <Route path="/landing">
        <LandingPage />
      </Route>
      <Route path="/:company?/watch/:ident/payment">
        {channelDataIsReady && showViewerPage2 ? (
          <StreamPage />
        ) : (
          <ViewerPage isPaymentModalDefaultOpen />
        )}
      </Route>
      <Route path="/:company?/watch/:ident">
        {channelDataIsReady && showViewerPage2 ? (
          <StreamPage />
        ) : (
          <ViewerPage isPaymentModalDefaultOpen={false} />
        )}
      </Route>

      <Route path="/:company/games/g/:ident/payment">
        {channelDataIsReady && showViewerPage2 ? (
          <StreamPage />
        ) : (
          <ViewerPage isPaymentModalDefaultOpen={true} />
        )}
      </Route>
      <Route path="/:company/games/g/:ident">
        {channelDataIsReady && showViewerPage2 ? (
          <StreamPage />
        ) : (
          <ViewerPage isPaymentModalDefaultOpen={false} />
        )}
      </Route>
      <Route path="/:company/impressum">
        <Impressum />
      </Route>
      <Route path="/search">
        <SearchResultPage />
      </Route>

      <Route path="/:company?/search">
        {channelDataIsReady && <SearchResultPage />}
      </Route>
      <Route path="/calendar">
        <SchedulePage isDefaultSchedule />
      </Route>
      <Route path="/:company/calendar">
        {channelDataIsReady && <SchedulePage isDefaultSchedule={false} />}
      </Route>
      <Route
        path={[
          '/login/callback',
          '/login',
          '/sign_up',
          '/password/new',
          '/profile/password/edit'
        ]}
      >
        <UserSessionPage />
      </Route>

      <Route
        path={[
          '/user/profile',
          '/user/profile/home',
          '/user/profile/account/details',
          '/user/profile/account/channels',
          '/user/profile/account/follows',
          '/user/profile/purchases/subscriptions',
          '/user/profile/purchases/purchases',
          '/user/profile/purchases/paymentinformation',
          '/user/profile/security/password'
        ]}
      >
        <UserAccountPage />
      </Route>
      <PrivateRoute path="/:company/settings">
        <ChannelSettings />
      </PrivateRoute>
      <PrivateRoute path="/:company/contentmanager">
        <ContentManager />
      </PrivateRoute>
      <Route path="/:company/news/:article">
        {channelDataIsReady && <ReadNewsArticle />}
      </Route>
      <Route path="/404" exact>
        <NotFoundPage />
      </Route>
      <Route>{channelDataIsReady && <ChannelPage />}</Route>
    </Switch>
  )
}
