import React, { FC, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { t } from '@lingui/macro'
import IconTextButton, {
  ButtonType
} from '../../../../../components/buttons/TextIconButton'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import {
  decrementStreamUserLikeCount,
  incrementStreamUserLikeCount
} from 'redux-store/stream/stream.actions'

import { isLikedByUser, IsLikedParams } from 'api/viewer/getIsLikedByUser'
import useCurrentUser from 'hooks/CurrentUser'
import { LikeIcon } from 'assets/icons/LikeIcon'
import { LikedIcon } from 'assets/icons/LikedIcon'
import { getUrlForStream } from 'helpers/PathHelper'
import { channelSelector } from 'redux-store/channel/channel.selectors'
import styles from './Buttons.module.scss'
import authenticationActions from 'redux-store/authentication/authentication.actions'
import { toggleLike } from 'api/viewer/toggleLikeForUser'

interface LikeButtonProps {
  showIconOnly?: boolean
}

export const LikeStreamButton: FC<LikeButtonProps> = ({
  showIconOnly = false
}) => {
  const stream = useSelector(streamSelector)
  const channel = useSelector(channelSelector)
  const mediaObject = stream.data.mediaObject
  const currentUser = useCurrentUser()
  const channelSlug = channel.data.subdomain
  const gameIdent = mediaObject?.game?.id
  const dispatch = useDispatch()
  const [isLiked, setIsLiked] = useState(false)
  const streamUrl = getUrlForStream(mediaObject, channelSlug)
  const descriptionUrl = `${window.origin}${streamUrl}`
  const likeCount = mediaObject?.user_like_counts
  const label = likeCount === 0 ? t`Like` : likeCount
  const params = mediaObject.game
    ? {
        company: channelSlug,
        game_ident: gameIdent,
        description_url: descriptionUrl
      }
    : {
        company: channelSlug,
        media_object_slug: mediaObject.slug,
        description_url: descriptionUrl
      }

  const onLikeClick = async () => {
    if (!currentUser) {
      dispatch(authenticationActions.openLoginLayer())
    } else {
      if (isLiked) {
        dispatch(decrementStreamUserLikeCount())
      } else {
        dispatch(incrementStreamUserLikeCount())
      }
      try {
        await toggleLike(isLiked, mediaObject.id)
        setIsLiked(!isLiked)
      } catch (err: any) {
        setIsLiked(isLiked)
        console.log('media object like error:', err)
      }
    }
  }

  useEffect(() => {
    const getUserLikes = async (params: IsLikedParams) => {
      const liked = await isLikedByUser(params)
      if (liked) setIsLiked(liked)
    }
    if (currentUser && params) {
      getUserLikes(params)
    }
  }, [currentUser, params])

  return (
    <IconTextButton
      externalStyle={styles.LikeButton}
      buttonSize="Medium"
      id="likeBtn"
      label={showIconOnly ? null : label}
      onClick={() => onLikeClick()}
      type={ButtonType.Secondary}
      isRounded
      data-testid="like-stream-button"
    >
      <div className={styles.IconStroke}>
        {isLiked ? <LikedIcon /> : <LikeIcon />}
      </div>
    </IconTextButton>
  )
}
