import React, { FC } from 'react'
import styles from './OptionsMenuItem.module.scss'
import SettingsIcon from '../../../Icons/SettingIcon'
import DownloadIcon from '../../../Icons/DownloadIcon'
import FlagIcon from '../../../Icons/FlagIcon'
import { HelpIcon } from '../../../Icons/HelpIcon'
import { InformationIcon } from 'components/Accordion/AccordionItem/InformationButton/InformationIcon'
import EditIcon from 'assets/icons/EditIcon'

const icons = {
  settings: SettingsIcon,
  download: DownloadIcon,
  flag: FlagIcon,
  help: HelpIcon,
  edit: EditIcon,
  broadcastInfo: InformationIcon
}

interface OptionsMenuItemProps {
  icon: 'settings' | 'download' | 'flag' | 'help' | 'broadcastInfo' | 'edit'
  onClick: () => void
  dataTestId: string
  children?: React.ReactNode
}

export const OptionsMenuItem: FC<OptionsMenuItemProps> = ({
  icon,
  children,
  dataTestId,
  onClick
}) => {
  const Icon = icons[icon]
  return (
    <div
      data-testid={dataTestId}
      className={styles.OptionsMenuItem}
      tabIndex={0}
      role="button"
      onKeyDown={() => null}
      onClick={onClick}
    >
      <Icon />
      <span>{children}</span>
    </div>
  )
}
