import React, { FC } from 'react'
import { StreamOverview } from './components/StreamOverview/StreamOverview'
import { StreamSubmenuSection } from './StreamSubmenuSection'

export const StreamPageLayout: FC = () => {
  return (
    <div>
      <StreamOverview />
      <StreamSubmenuSection />
    </div>
  )
}
