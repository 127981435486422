import { t } from '@lingui/macro'
import { LinkType, MenuLink } from 'components/page/menuLink/MenuLink'

interface ViewerPageSubmenuType extends MenuLink {
  id: number
}

export enum ViewerPageMenuOption {
  AboutStream = 1,
  Chat = 2,
  Statistics = 3,
  Lineup = 4
}

const menuItems: ViewerPageSubmenuType[] = [
  {
    id: ViewerPageMenuOption.AboutStream,
    translationText: t`About`,
    type: LinkType.Route,
    link: '/about'
  },
  {
    id: ViewerPageMenuOption.Chat,
    translationText: t`Chat`,
    type: LinkType.Route,
    link: '/chat'
  },
  {
    id: ViewerPageMenuOption.Statistics,
    translationText: t`Statistics`,
    type: LinkType.Route,
    link: '/statistics'
  },
  {
    id: ViewerPageMenuOption.Lineup,
    translationText: t`Lineup`,
    type: LinkType.Route,
    link: '/lineup'
  }
]

export default menuItems
