import React, { FC } from 'react'
import { t, Trans } from '@lingui/macro'
import { useSelector } from 'react-redux'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import IconTextButton, { ButtonType } from 'components/buttons/TextIconButton'
import ForwardIcon from 'pages/BroadcastStartpage/TestimonialsSection/ForwardIcon'
import { MapPinIcon } from 'assets/icons/MapPinIcon'
import { onHelpClick } from 'pages/ViewerPage/VideoPlayerSection/onHelpClick'
import styles from './GeoBlockedInfo.module.scss'

export const GeoBlockedInfo: FC = () => {
  const stream = useSelector(streamSelector)
  const mediaObject = stream.data.mediaObject
  const streamTitle = mediaObject.title

  return (
    <div className={styles.Container}>
      <div className={styles.Icon}>
        <MapPinIcon />
      </div>
      <div className={styles.TextContainer}>
        <h1 className={styles.Title}>{streamTitle}</h1>
        <p className={styles.Text}>
          <Trans>
            The stream you are looking for is not available in your region.
          </Trans>
        </p>
      </div>
      <IconTextButton
        id={'read-more-button'}
        type={ButtonType.Secondary}
        buttonSize={'Medium'}
        label={t`Read more here`}
        onClick={() => onHelpClick()}
        externalStyle={styles.ReadMoreButton}
        isRounded
      >
        <ForwardIcon />
      </IconTextButton>
    </div>
  )
}
