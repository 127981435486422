import React, { FC, useState } from 'react'
import classNames from 'classnames'
import SearchIcon from './SearchIcon'
import CrossIcon from './CrossIcon'
import styles from './SearchField.module.scss'
import BackArrow from 'pages/ViewerPage/PaymentModal/VoucherSection/BackArrow'

export enum ColorType {
  Dark = 'Dark',
  Light = 'Light',
  White = 'White'
}
interface Props {
  type: ColorType
  onSearch: (searchText: string) => void
  onClearSearchText?: () => void
  isFocused?: boolean
  placeholder: string
  showBackArrow: boolean
  onBackArrowClick: () => void
}
export const SearchField: FC<Props> = ({
  type,
  onSearch,
  isFocused,
  onClearSearchText,
  showBackArrow,
  placeholder,
  onBackArrowClick
}) => {
  const [searchInput, setSearchInput] = useState('')

  const clearSearchField = () => {
    setSearchInput('')
    {
      onClearSearchText && onClearSearchText()
    }
  }
  return (
    <div className={classNames(styles.SearcfieldContainer, styles[type])}>
      {showBackArrow && onBackArrowClick && (
        <div className={styles.BackArrow} onClick={onBackArrowClick}>
          <BackArrow />
        </div>
      )}
      <div className={styles.SearchIcon} onClick={() => onSearch(searchInput)}>
        <SearchIcon />
      </div>
      <input
        data-testid="search-input"
        className={styles.SearchInput}
        type="text"
        placeholder={placeholder}
        onChange={(event) => {
          setSearchInput(event.target.value)
        }}
        autoFocus={isFocused}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            onSearch(searchInput)
          }
        }}
        tabIndex={0}
        value={searchInput}
      />
      {searchInput && (
        <div
          role="button"
          className={styles.CrossIcon}
          onClick={() => {
            clearSearchField()
          }}
          tabIndex={0}
        >
          <CrossIcon />
        </div>
      )}
    </div>
  )
}
