/* eslint-disable @typescript-eslint/indent */
import React, { FC, useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  channelSelector,
  isSwedishHandballSelector
} from 'redux-store/channel/channel.selectors'

import GameInteractions from 'pages/ViewerPage/GameInteractions'
import { AboutStream } from 'pages/ViewerPage/AboutStream/AboutStream'
import { Statistics } from 'pages/ViewerPage/Statistics/Statistics'
import { Lineups } from 'pages/ViewerPage/Lineups/Lineups'
import { streamSelector } from 'redux-store/stream/stream.selectors'
import MEDIA_OBJECT from 'constants/media_object.constants'
import { VideoPlayerData } from 'pages/ViewerPage/viewerPage.interfaces'
import menuItems, {
  ViewerPageMenuOption
} from 'pages/ViewerPage/ViewerPageSubmenu/viewerPageSubmenuLinks'
import { getSelectedLink } from 'pages/ViewerPage/getSelectedLink'
import { getBroadcastPath } from 'pages/ViewerPage/getBroadcastPath'
import { StreamSubmenu } from './components/StreamSubmenu/StreamSubmenu'

export const StreamSubmenuSection: FC = () => {
  const { pathname } = location
  const channel = useSelector(channelSelector)
  const stream = useSelector(streamSelector)
  const isSwedishHandball = useSelector(isSwedishHandballSelector)
  const mediaObject = stream.data.mediaObject
  const selectedLink = getSelectedLink(menuItems, pathname)
  const fallbackMenuId = ViewerPageMenuOption.AboutStream
  const initialSelectedMenu = selectedLink ? selectedLink.id : fallbackMenuId
  const [selectedMenu, setSelectedMenu] = useState(initialSelectedMenu)
  const showGameLineups = isSwedishHandball
  const { hasGame, canComment } = stream
  const mediaPlayerData: VideoPlayerData = stream.data

  const showChat =
    mediaObject?.type === MEDIA_OBJECT.TYPES.LIVESTREAM ||
    mediaObject?.is_replay

  const broadcastPath =
    mediaPlayerData &&
    getBroadcastPath(pathname, channel?.data?.subdomain, mediaPlayerData)

  useEffect(() => {
    const items = menuItems.filter((link) =>
      link.id === ViewerPageMenuOption.Chat ? canComment : true
    )
    if (items.length === 1) {
      setSelectedMenu(items[0].id)
    } else {
      setSelectedMenu(initialSelectedMenu)
    }
  }, [canComment, selectedLink, initialSelectedMenu])

  return (
    <>
      <StreamSubmenu
        onItemSelect={(id) => setSelectedMenu(id)}
        selectedItemId={selectedMenu}
        broadcastPath={broadcastPath}
        pageHasChat={showChat}
        canComment={canComment}
        hasGame={hasGame}
      />
      <Switch>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/chat',
            '/:company/watch/:ident/chat',
            '/:company/games/g/:ident/comments',
            '/:company/watch/:ident/comments'
          ]}
        >
          {canComment ? (
            <GameInteractions mediaObject={mediaObject} />
          ) : (
            <AboutStream mediaPlayerData={mediaPlayerData} />
          )}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/statistics',
            '/:company/watch/:ident/statistics'
          ]}
        >
          {hasGame && <Statistics />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident/lineup',
            '/:company/watch/:ident/lineup'
          ]}
        >
          {showGameLineups && <Lineups />}
        </Route>
        <Route
          exact
          path={[
            '/:company/games/g/:ident',
            '/:company/watch/:ident',
            '/:company/games/g/:ident/about',
            '/:company/games/g/:ident/payment',
            '/:company/watch/:ident/about',
            '/:company/watch/:ident/payment'
          ]}
        >
          <AboutStream mediaPlayerData={mediaPlayerData} />
        </Route>
      </Switch>
    </>
  )
}
