import { uiConstants } from './userInterface.reducer'

const openMenu = () => ({
  type: uiConstants.OPEN_MENU
})
const closeMenu = () => ({
  type: uiConstants.CLOSE_MENU
})
const openReportContentDialog = () => ({
  type: uiConstants.OPEN_REPORT_CONTENT_DIALOG
})
const closeReportContentDialog = () => ({
  type: uiConstants.CLOSE_REPORT_CONTENT_DIALOG
})
const openBroadcastInformationModal = () => ({
  type: uiConstants.OPEN_BROADCAST_INFROMATION_MODAL
})
const closeBroadcastInformationModal = () => ({
  type: uiConstants.CLOSE_BROADCAST_INFROMATION_MODAL
})
const openQuickEditContentDialog = () => ({
  type: uiConstants.OPEN_QUICK_EDIT_DIALOG
})
const closeQuickEditContentDialog = () => ({
  type: uiConstants.CLOSE_QUICK_EDIT_DIALOG
})

const openPaymentDialog = () => ({
  type: uiConstants.OPEN_PAYMENT_DIALOG
})
const closePaymentDialog = () => ({
  type: uiConstants.CLOSE_PAYMENT_DIALOG
})
const openShareContentModal = () => ({
  type: uiConstants.OPEN_SHARE_CONTENT_MODAL
})
const closeShareContentModal = () => ({
  type: uiConstants.CLOSE_SHARE_CONTENT_MODAL
})
const openViewStream = () => ({
  type: uiConstants.OPEN_VIEW_STREAM
})
const closeViewStream = () => ({
  type: uiConstants.CLOSE_VIEW_STREAM
})

export const userInterface = {
  openMenu,
  closeMenu,
  openReportContentDialog,
  closeReportContentDialog,
  openBroadcastInformationModal,
  closeBroadcastInformationModal,
  openQuickEditContentDialog,
  closeQuickEditContentDialog,
  openPaymentDialog,
  closePaymentDialog,
  openShareContentModal,
  closeShareContentModal,
  openViewStream,
  closeViewStream
}
